.amazon-pay-account-error-message,
.amazon-pay-cart-error-message {
    color: red;
    margin-bottom: 0.5rem;
}

.amazon-pay-account-result-message {
    color: red;
    margin: 2rem 0 0.5rem;
}

.mini-cart-link-cart {
    width: 200px;
}

.amazon-pay-button-component {
    margin-top: 0.5em;

    .amazon-pay-cart-description {
        display: none;
    }
}

.amazon-pay-mini-cart-button-component {
    width: 200px;
    margin: 0.5em auto 0;
}

.amazon-pay-mini-cart-description {
   display: none;
}

.amazon-pay-cart-description {
    width: 164px;
}

.amazon-pay-button {
    margin: auto;
}

.amazon-pay-apb-button-component {
    width: 100%;
    float: right;
}

.amazon-pay-apb-button-frame {
    width: 180px;
    float: right;
}

.amazon-shipping-address-label {
    margin-top: 0.5rem;
    font-size: 1.1em;
    font-weight: 700;
    width: 25%;
    float: left;
    text-align: right;
}

.amazon-shipping-address-value {
    margin-top: 0.5rem;
    font-size: 1.1em;
    font-weight: 700;
    width: 70%;
    float: right;
    text-align: left;
}

.amazon-pay-shipping-address-row {
    margin-bottom: 2px;
    display: table;
    width: 100%;
}

.amazon-pay-button-external-profile {
    width: 250px;
}

.amazon-pay-checkout-error-message {
    display: none;
}

.amazon-pay-checkout-login-only {
    float: left;
}

.amazon-pay-payment-method-description {
    max-width: 450px;
    margin-bottom: 1em;
}

.amazon-pay-row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
        display: block;
    }

    .button {
        @media (max-width: 767px) {
            width: 100%;
            margin-top: 10px;
        }
    }

    &::after {
        display: none;
    }
}
